/**
* Home template JS.
* 
* @copyright © 2016 51North, Sittard NL. All rights reserved.
* @see 51north.nl
* @author Guido Smeets, Kyle Adams
* @version 1.0
*/
pageJS = function() {
	var _this = this;

	//PROPERTIES
	this.stream = null;

	//infinitescroll
	this.fetching = false;
	this.windowWidth = 0;

	/**
	* Show this template. 
	* This function is called after the page transition show animation is complete,
	* everything that has to wait/can wait after the page is show can be placed here.
	*/
	this.show = function() {
		//this.setupInfiniteScroll();
		$(this).trigger('PAGE_SHOW_COMPLETE');
	};

	/**
	* Build this template.
	* This function is called before the actual page show transition is played,
	* everything that needs to be initialized before the animation should be placed here.
	*/
	this.build = function() {
		if (_this.navigation.changedPage) $('.block').css('opacity', 0);
		_this.repos.stream = new _this.stack($('.blocks_holder'));

		//clone clients for mobile with carousel
		var dolly = $('#clients_holder').clone();
		dolly.attr('id', 'clients_holder_mob');
		$('#clients_mob_holder').append(dolly);
		
		$("#clients_holder_mob").owlCarousel({
			items: 1
		});

		$(this).trigger('PAGE_BUILD_COMPLETE');
	};

	/**
	* Setup infinite scroll logic for grid
	*/
	this.setupInfiniteScroll = function() {
		//setup infinite scroll logic
		this.fetching = false;

		$(window).on('scroll.PAGE_HOME', function(){
            if ($(window).scrollTop() == $(document).height() - $(window).height()) {
            	//user reached bottom
		        if (!_this.fetching) {
		        	//we are not fetching and fetching of items is complete
		        	//last check: if current page < total, we trigger another fetch
		        	if ($('#pagination').attr('data-total') > $('#pagination').attr('data-current')) _this.handleInfiniteScroll();
		        }
            }
        }); 
	};

	/**
	* Handle infinite scroll logic for grid
	*/
	this.handleInfiniteScroll = function() {
		_this.fetching = true;
		//$('#ajax_loader').show(0);
		$.get($('#pagination').attr('data-next-url'), function(response) {
			var ajaxHTML = $('<div></div>').append(response);
			var tplData = $(ajaxHTML).find('.blocks_holder .block');
			var paginationHTML = $(ajaxHTML).find('#pagination')[0];
			var appended = '';

			//create appended string
			$(tplData).each(function(i,o) {
				if (i > 0) {
					appended += $(o)[0].outerHTML;
				}
			});

			//update pagination data in dom
			$('#pagination').attr('data-current', $(paginationHTML).attr('data-current'));
			$('#pagination').attr('data-next-url', $(paginationHTML).attr('data-next-url'));

			var $newItems = $(appended);
			$('.blocks_holder').isotope('insert', $newItems);
			_this.positionStackItems($newItems);

			//set fetching to false to app knows it can fetch again
			_this.fetching = false;

			//pause for effect, then scroll down a little
			setTimeout(function() {
				//$('#ajax_loader').hide(0);
				TweenMax.to($(window), 0.5, {scrollTo:{y:($(window).scrollTop() + 200), autoKill: false}});
			}, 500);
		});
	};

	/**
	* Kill this template.
	* This function is called before the content_holder is removed from DOM,
	* so kill any listeners/destroy plugins that need to be destroyed manually
	*/
	this.kill = function() {
		_this.repos.stream.destroy();

		$(this).trigger('PAGE_KILL_COMPLETE');
	};

};